import VisibilityOnIcon from '../assets/visibility_on.png';
import VisibilityOffIcon from '../assets/visibility_off.png';

const VisibilityIcon = ({ visible, setVisible }) => {
    return (<div className="input-icon" onClick={() => setVisible(!visible)}>
        <img
            src={visible ? VisibilityOnIcon : VisibilityOffIcon}
            alt="toggle visibility"
            className="visibility-icon"
        />
    </div>);
}

export default VisibilityIcon;