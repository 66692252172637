import React from 'react';
import './EvaluationProgressBar.css'

function EvaluationProgressBar({ value }) {
    const progressValue = Math.max(0, Math.min(value, 100));

    return (
        <div>
            <div className="evaluation-progress-bar progress">
                <div role="progressbar" className="progress-bar bg-primary progress-bar-animated progress-bar-striped" style={{ width: `${Math.max(2, progressValue)}%`, fontWeight: 800, fontSize: 15, minWidth: '30px' }}>{`${progressValue}%`}</div>
            </div>
        </div>
    );
}

export default EvaluationProgressBar;
