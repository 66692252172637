import React, { useState } from 'react';
import { InputGroup, Form, Button, Spinner, Toast } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './RegistrationForm.css';
import '../shared/form-elements.css';
import ApiService from '../apis/ApiService';
import TermsText from './TermsText';
import VisibilityIcon from '../shared/VisibilityIcon';

const validationSchema = Yup.object().shape({
    username: Yup.string()
        .min(3, 'Company Name must be at least 3 characters')
        .required('Company Name is required'),
    email: Yup.string()
        .email('Invalid email format')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            'Invalid email format'
        )
        .required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    terms: Yup.bool().oneOf([true], 'Terms must be accepted'),
});


const apiService = new ApiService(null, null, false);

const RegistrationForm = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await apiService._post(`register/`, {
                name: values.username,
                email: values.email,
                password: values.password,
            });
            if (response.status === 200) {
                resetForm();
                navigate('/register-success');
            }
        } catch (error) {
            console.error(error);
            setErrorMessage(error.response?.data?.detail || 'An error occurred');
            setShowErrorToast(true);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={{
                    username: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    terms: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                    <Form noValidate onSubmit={handleSubmit} className="w-100">
                        <Form.Group className=" form-group-custom w-100">
                            <Form.Label htmlFor="username" className="auth-form-label">Company Name</Form.Label>
                            <Field
                                id="username"
                                type="text"
                                name="username"
                                placeholder="Enter your company's name"
                                value={values.username || ''}
                                onChange={handleChange}
                                className={`form-control auth-form-control w-100 ${touched.username && errors.username ? 'is-invalid' : ''}`}
                                autoComplete="username"
                            />
                            <ErrorMessage component="div" name="username" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className=" form-group-custom w-100">
                            <Form.Label htmlFor="email" className="auth-form-label">Email</Form.Label>
                            <Field
                                id="email"
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                value={values.email || ''}
                                onChange={handleChange}
                                className={`form-control auth-form-control w-100 ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                autoComplete="email"
                            />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="form-group-custom">
                            <Form.Label htmlFor="password" className="auth-form-label">Password</Form.Label>
                            <InputGroup className="input-with-icon">
                                <Field
                                    id="password"
                                    autoComplete="new-password"
                                    type={passwordVisible ? "text" : "password"}
                                    name="password"
                                    className={`form-control auth-form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                    placeholder="Enter your password"
                                    value={values.password}
                                    onChange={handleChange}
                                />
                                {!(touched.password && errors.password) && <VisibilityIcon visible={passwordVisible} setVisible={setPasswordVisible} />}
                            </InputGroup>
                            <ErrorMessage component="div" name="password" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="form-group-custom">
                            <Form.Label htmlFor="confirmPassword" className="auth-form-label">Confirm Password</Form.Label>
                            <InputGroup className="input-with-icon">
                                <Field
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    name="confirmPassword"
                                    className={`form-control auth-form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                                    placeholder="Confirm your password"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                />
                                {!(touched.confirmPassword && errors.confirmPassword) && <VisibilityIcon visible={confirmPasswordVisible} setVisible={setConfirmPasswordVisible} />}
                            </InputGroup>
                            <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group className="rbhc-form-terms w-100">
                            <Field
                                type="checkbox"
                                name="terms"
                                id="terms"
                                className={`${touched.terms && errors.terms ? 'is-invalid' : ''}`}
                            />
                            <label htmlFor="terms" className="register-terms-text">
                                I agree to all the
                                <span onClick={e => {
                                    e.preventDefault()
                                    setShowTerms(true)
                                }}>
                                    Terms
                                </span>
                                and
                                <span onClick={e => {
                                    e.preventDefault()
                                    window.open('https://www.batterycycle.io/privacy-policy/', '_blank')
                                }}>
                                    Privacy Policies
                                </span>
                            </label>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={isSubmitting} className='w-100'>
                            {isSubmitting ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                'Register'
                            )}
                        </Button>
                    </Form>

                )}
            </Formik>

            <div className="toast-container-center">
                <Toast show={showTerms} onClose={() => setShowTerms(false)} className="toast-info">
                    <Toast.Header>
                        <strong className="me-auto">Terms and Conditions</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <TermsText />
                    </Toast.Body>
                </Toast>
                <Toast show={showErrorToast} onClose={() => setShowErrorToast(false)} className="toast-error">
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {errorMessage}
                    </Toast.Body>
                </Toast>
            </div>
        </>
    );
};

export default RegistrationForm;
