import ApiService from './ApiService';

class EvaluationsApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    create() {
        return this._post('/evaluations');
    }

    getAll() {
        return this._get('/evaluations');
    }

    get(code) {
        return this._get(`/evaluations/${code}`);
    }

    start(code, initial_battery_percentage, battery_capacity, updates_email = "") {
        return this._post('/evaluations/start', { code, initial_battery_percentage, battery_capacity, updates_email });
    }

    changeStatus(code, status) {
        return this._post('/evaluations/change_status', { code, status });
    }

    cancel(code) {
        return this._post('/evaluations/cancel', { code });
    }

    reset(code) {
        return this._post('/evaluations/reset', { code })
    }

    finish(code, charging_kwh, user_end_time) {
        return this._post('/evaluations/finish', { code, charging_kwh, user_end_time });
    }

    progress(code) {
        return this._get(`/evaluations/${code}/progress`);
    }

    getForReport(code) {
        return this._get(`/evaluations/${code}/report`);
    }

    getOwner(code) {
        return this._get(`/evaluations/${code}/owner`, { code });
    }
}

export default EvaluationsApi;
