import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

import '../shared/form-containers.css';
import EvaluationsApi from '../apis/Evaluations';
import './CharginDetailPage.css';
import FormTooltip from '../shared/FormTooltip';

const ChargingDetailsPage = ({ evaluation, setEvaluation }) => {
    const [kWhUsed, setKWhUsed] = useState('');
    const [userEndTime, setUserEndTime] = useState('');
    const evaluationsApi = new EvaluationsApi(null, null, false);

    const handleSubmit = () => {
        evaluationsApi.finish(evaluation.code, kWhUsed, userEndTime).then(r => {
            setEvaluation(r.data);
        });
    };

    const handleKWhUsedChange = e => {
        const value = e.target.value;
        if (value === '' || Number(value) >= 0) {
            setKWhUsed(value);
        }
    };

    const handleUserEndTimeChange = e => {
        setUserEndTime(e.target.value);
    };

    return (
        <div className="rbh-container battery-test-completion">
            <Row className="text-start align-items-start">
                <Col md={12}>
                    <h1 className="battery-test-completion-header">Charging Data</h1>
                    <p className="battery-test-completion-text-first">
                        We've captured enough data to complete the test, we will stop collecting data when you stop charging and complete the test.
                    </p>
                    <div className="row flex" style={{ justifyContent: "center" }}>
                        <p className="battery-test-completion-text-second">
                            To complete the test, stop charging and fill in the details on the charging session below
                        </p>
                    </div>
                    <Form>
                        <Form.Group controlId="kWhUsed" className="mt-3">
                            <FormTooltip label="Total Energy Used" tooltip_text='Enter the total energy (in kilowatt-hours) consumed during the charging session. This value is typically displayed on the charging station or vehicle dashboard. Example: If your charging station indicates you used 15.7 kWh during the session, enter "15.7."' />
                            <Form.Control
                                type="number"
                                placeholder='Enter the total energy used during the charging session in "kWh" e.g., 12.5'
                                value={kWhUsed}
                                onChange={handleKWhUsedChange}
                                min="0"
                            />
                        </Form.Group>
                        <Form.Group controlId="userEndTime" className="mt-3">
                            <FormTooltip label="End Time of the Charging Session" tooltip_text="Enter the exact time when the charging session ended. You can find this on your charging station or record it manually at the end of charging." />
                            <Form.Control
                                type="time"
                                value={userEndTime}
                                onChange={handleUserEndTimeChange}
                            />
                        </Form.Group>
                        <Row className="mt-4">
                            <Col className="text-end">
                                <Button variant="primary" className="w-100" onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ChargingDetailsPage;
