import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import EvaluationsApi from '../apis/Evaluations';
import EvaluationProgressBar from './EvaluationProgressBar'
import useEvaluationProgress from './useEvaluationProgress';
import Loading from '../shared/Loading';
import AutomationIcon from './automation_icon.png'
import './EvaluationProgressPage.css'
import CancelEvaluationModal from './CancelEvaluationModal';

const evaluationsApi = new EvaluationsApi(null, null, false);
const buttonWidth = 110

const EvaluationProgressPage = ({ evaluation, setEvaluation }) => {
    const handleCancel = () => evaluationsApi.cancel(evaluation.code).then(r => setEvaluation(r.data))
    const [showCancelModal, setShowCancelModal] = useState(false);

    const { isLoading: progressIsLoading, progress } = useEvaluationProgress(
        evaluation?.code,
        setEvaluation
    );

    if (progressIsLoading) {
        return <Loading />
    }

    return (
        <Container className="text-center mt-5">
            <h1 className='evaluation-progress-header'>Battery Assessment</h1>
            <div className="capturing-data-wrapper">
                <img src={AutomationIcon} alt="automation icon" className='automation-icon' />
                <h4 className='evaluation-capturing-text'>Capturing Data is in progress...</h4>
            </div>

            <Row className="align-items-center mt-4">
                <Col>
                    <EvaluationProgressBar value={progress} />
                </Col>
            </Row>

            <p className="mt-4">
                When the test is finished, you will be asked to fill out details on the charging session. You need to be at your car to do this.
            </p>

            <Row className="justify-content-center">
                <Col xs="auto">
                    <Button onClick={() => setShowCancelModal(true)} style={{ width: buttonWidth }} className='progress-bar-close-brn'>
                        Cancel
                    </Button>
                </Col>
            </Row>

            <CancelEvaluationModal
                showModal={showCancelModal}
                setShowModal={setShowCancelModal}
                handleAffirmative={handleCancel}
            />
        </Container>
    );

};

export default EvaluationProgressPage;
