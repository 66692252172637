import React from 'react';
import logo from '../assets/logo-no-background.png';
import './LogoHeader.css';

const LogoHeader = () => {

    return (
        <img src={logo} alt="Header Logo" className="logo-header" />
    );
};

export default LogoHeader;
