import { useState, useEffect } from 'react';
import { Row, Col, InputGroup, Button, Toast, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import { LinkContainer } from 'react-router-bootstrap';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import ChevronBackward from '../assets/chevron_backward.png';
import UsersApi from '../apis/Users';
import LinkExpired from './LinkExpired';
import PasswordChanged from './PasswordChanged';
import EVImage from '../shared/EVImage';
import VisibilityIcon from '../shared/VisibilityIcon';
import Loading from '../shared/Loading'
import "./ResetPassword.css"

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const usersApi = new UsersApi(null, null, false);

const ErrorToast = ({ show, setShow, message }) => (
    <div className="toast-container-center">
        <Toast show={show} onClose={() => setShow(false)} className="toast-error">
            <Toast.Header>
                <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    </div>
);

const ResetPassword = () => {
    const { verification_guid } = useParams();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [linkExpired, setLinkExpired] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const checkLinkExpiration = async () => {
            try {
                const response = await usersApi.reset_password_has_expired(verification_guid);
                if (response.data.expired) {
                    setLinkExpired(true);
                }
            } catch (error) {
                console.error('Error checking link expiration:', error);
                setErrorMessage('An error occurred while verifying the link.');
                setShowErrorToast(true);
            } finally {
                setIsLoading(false)
            }
        };

        checkLinkExpiration();
    }, [verification_guid]);

    const handleSubmit = async (values) => {
        try {
            const response = await usersApi.reset_password(verification_guid, values.password);
            if (!response.data.success) {
                setLinkExpired(true);
                setErrorMessage('This reset link has expired.');
                setShowErrorToast(true);
            } else {
                setPasswordResetSuccess(true);
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            setErrorMessage('An error occurred while resetting the password.');
            setShowErrorToast(true);
        }
    };

    if (isLoading) {
        return <Loading />
    }

    if (linkExpired) {
        return <LinkExpired />
    }

    if (passwordResetSuccess) {
        return <PasswordChanged />
    }

    return (
        <Row className="rbh-container">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container login-container">
                        <div className='back-login-wrapper'>
                            <LinkContainer to="/login">
                                <img
                                    src={ChevronBackward}
                                    alt="chevron icon"
                                    className='back-login-icon'
                                />
                            </LinkContainer>
                            <p style={{ cursor: "pointer" }}>Back to Login page</p>
                        </div>

                        <div className="forget-password-text-wrapper">
                            <h1 className='forget-password-header'>Enter a new password for your account</h1>
                            <p className='forget-password-text'>All fields are mandatory</p>
                        </div>

                        <Formik
                            initialValues={{
                                password: '',
                                confirmPassword: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="form-group-custom">
                                        <Form.Label htmlFor="password" className="auth-form-label">Password</Form.Label>
                                        <InputGroup className="input-with-icon">
                                            <Field
                                                id="password"
                                                autoComplete="new-password"
                                                type={passwordVisible ? "text" : "password"}
                                                name="password"
                                                placeholder="Enter your password"
                                                value={values.password}
                                                onChange={handleChange}
                                                className={`form-control auth-form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                            />

                                            {!(touched.password && errors.password) && <VisibilityIcon visible={passwordVisible} setVisible={setPasswordVisible} />}
                                            <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group className="form-group-custom">
                                        <Form.Label htmlFor="confirmPassword" className="auth-form-label">Confirm Password</Form.Label>
                                        <InputGroup className="input-with-icon">
                                            <Field
                                                id="confirmPassword"
                                                autoComplete="new-password"
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                name="confirmPassword"
                                                placeholder="Confirm your password"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                className={`form-control auth-form-control ${touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''}`}
                                            />
                                            {!(touched.confirmPassword && errors.confirmPassword) && <VisibilityIcon visible={confirmPasswordVisible} setVisible={setConfirmPasswordVisible} />}
                                            <ErrorMessage component="div" name="confirmPassword" className="invalid-feedback" />
                                        </InputGroup>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-3 w-100">
                                        {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <EVImage />
            </Col>

            <ErrorToast show={showErrorToast} setShow={setShowErrorToast} message={errorMessage} />
        </Row>
    );
};

export default ResetPassword;
