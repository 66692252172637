import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EvaluationsApi from '../apis/Evaluations';
import Loading from '../shared/Loading';
import { joinUrl } from '../util';


const evaluationsApi = new EvaluationsApi(null, null, false);

const Continue = () => {
    const { evaluationCode } = useParams();

    useEffect(() => {
        if (evaluationCode) {
            evaluationsApi.reset(evaluationCode).then(r => {
                window.location.href = joinUrl(window.location.origin, `evaluations/`, evaluationCode)
            })
        }
    }, [evaluationCode])

    return <Loading />
}

export default Continue