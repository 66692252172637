// import BatteryIcon from '../assets/battery_change.png';
import './SOHDescription.css'
import { minSOHValue, maxSOHValue, poorHealthLimits, fairHealthLimits, goodHealthLimits, excellentHealthLimits } from './SOHConfig'

const SOHDescription = ({ soh }) => {
    let ratingMessage = <p>Invalid State of Health value</p>;

    if (soh < minSOHValue || soh > maxSOHValue) {
        // You may do something extra here...
    } else if (soh < poorHealthLimits.upper_limit) {
        ratingMessage = (
            <p>The EV’s battery is approaching the end of its useful life. Its range is significantly lower than the original WLTP estimate, and continued use may require battery reconditioning or replacement to ensure better performance.</p>
        );
    } else if (soh < fairHealthLimits.upper_limit) {
        ratingMessage = (
            <p>The EV’s battery is functional but underperforming. It provides less range than the WLTP estimate, and its current performance is below expectations. Additional testing or maintenance may be needed to improve its efficiency.</p>
        );
    } else if (soh < goodHealthLimits.upper_limit) {
        ratingMessage = (
            <p>The EV’s battery is in good condition, operating close to its expected WLTP range. Some minor adjustments in driving habits or charging patterns could help extend the range and lifespan, but the battery remains stable overall.</p>
        );
    } else if (soh < excellentHealthLimits.upper_limit) {
        ratingMessage = (
            <p>The EV’s battery is in excellent condition, showing performance near or at the WLTP range based on this test. This indicates the battery will perform at a high level and degrade at or below expected rates according to usage.</p>
        );
    }

    return (
        // <div className="battery-rating-wrapper">
        // <img src={BatteryIcon} alt="battery icon" className='battery-rating-icon' />
        <div className='battery-rating-text'>
            {ratingMessage}
        </div>
        // </div>
    );
};

export default SOHDescription;
