import StandaloneMessagePage from "../shared/StandaloneMessagePage";
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import '../shared/StandaloneMessagePage';
import linkExpiredIcon from '../assets/link_expired.png'

const LinkExpired = () => {
    return (
        <StandaloneMessagePage
            h1Text={"Link Expired"}
            pText={"The reset password link has expired. Please request a new link."}
            icon={linkExpiredIcon}
            button={<LinkContainer to="/forgot-password">
                <Nav.Link className='go-to-link'>Request another link</Nav.Link>
            </LinkContainer>}
        />
    );
}

export default LinkExpired