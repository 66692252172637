import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { joinUrl } from '../util';

const CancelledPage = ({ evaluation }) => {
    function continueTest() {
        window.location.href = joinUrl(window.location.origin, `evaluations/continue/`, evaluation?.code)
    }

    useEffect(() => {

    }, [])

    return (
        <Container className="text-center mt-5">
            <h3>Thanks for your input</h3>
            <p>Your test has been cancelled and no data was captured.</p>
            <p>
                To re-start the test, click the button below. If you prefer to do
                the test later, an email with the link will be sent.
            </p>
            <Button variant="primary" className="mt-3" onClick={continueTest}>
                Continue Test
            </Button>
        </Container>
    );
};

export default CancelledPage;