import ev_image from '../assets/ev_image.jpeg';

const EVImage = () => {
    return (
        <div className='layout_image_wrapper'>
            <img src={ev_image} alt="" />
        </div>
    );
}

export default EVImage