import React, { useState } from 'react';
import ReportsApi from '../apis/Reports';
import { Button, Alert, Row, Col, Card } from 'react-bootstrap';

const Administration = ({ navigate }) => {
    const [error, setError] = useState(null);

    const downloadErrorsReport = async () => {
        const reportsApi = new ReportsApi(navigate, '/login', true);

        try {
            setError(null);

            const response = await reportsApi.errors_report();

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'text/csv' });

            const now = new Date();
            const formattedDate = now
                .toISOString()
                .replace('T', '_')
                .replaceAll(':', '-')
                .split('.')[0]; // Format: YYYY-MM-DD_HH-MM-SS
            const fileName = `errors_report_${formattedDate}.csv`;

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Failed to download errors report:', error);
            setError('Failed to download errors report. Please try again later.');
        }
    };

    return (
        <Card className="mb-3 reports-card">
            <Card.Header>
                <h5 className="mb-0">Reports</h5>
            </Card.Header>
            <Card.Body className="mt-3">
                {error && <Alert variant="danger">{error}</Alert>}
                <Row className="align-items-center">
                    <Col xs={12} className="mb-2" >
                        <p className="mb-1">Download error report for all tests:</p>
                    </Col>
                    <Col xs={12}>
                        <Button
                            variant="primary"
                            onClick={downloadErrorsReport}
                        >
                            Download Errors Report
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Administration;
