import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from './info.png';
import "../evaluation/PreliminaryDetailsEntryPage.css"

const FormTooltip = ({ label, tooltip_text }) => {
    return (
        <div className="d-flex align-items-center">
            <Form.Label className="preliminary-label">{label}</Form.Label>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`${label.replace(/\s/g, '')}Tooltip`}>
                        {tooltip_text}
                    </Tooltip>
                }
            >
                <img src={InfoIcon} alt="info icon" className="info_icon ms-2" />
            </OverlayTrigger>
        </div>
    );
};

export default FormTooltip