import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Alert } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import { LinkContainer } from 'react-router-bootstrap';
import ChevronBackward from '../assets/chevron_backward.png';
import './ForgotPassword.css';
import UsersApi from '../apis/Users';
import Loading from '../shared/Loading'
import EVImage from '../shared/EVImage';
import ForgotPasswordMailSent from './ForgotPasswordMailSent';

const usersApi = new UsersApi(null, null, false);

const ForgotPassword = () => {
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            await usersApi.send_reset_password_email(values.email);
            setEmailSent(true);
        } catch (error) {
            console.error('Error sending reset password email:', error);
            const errorMsg = error.response?.data?.detail || 'An error occurred';
            setError(typeof errorMsg === 'string' ? errorMsg : JSON.stringify(errorMsg));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loading />
    }

    if (emailSent) {
        return <ForgotPasswordMailSent />
    }

    return (
        <Row className="rbh-container">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="login-container">
                        <div className='back-login-wrapper'>
                            <LinkContainer to="/login">
                                <img
                                    src={ChevronBackward}
                                    alt="chevron icon"
                                    className='back-login-icon'
                                />
                            </LinkContainer>
                            <p>Back to Login page</p>
                        </div>

                        <div className="forget-password-text-wrapper">
                            <h1 className='forget-password-header'>Forgot Password?</h1>
                            <p className='forget-password-text'>Enter your registered email address to receive password reset instructions</p>
                        </div>

                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={handleSubmit}
                        >
                            {({ handleSubmit }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3 form-group-custom">
                                        <Form.Label htmlFor="email" className="auth-form-label">Email</Form.Label>
                                        <Field
                                            id="email"
                                            autoComplete="email"
                                            type="email"
                                            name="email"
                                            className="form-control auth-form-control"
                                            placeholder="Enter your email address"
                                        />
                                    </Form.Group>
                                    {error && (
                                        <Alert variant="danger" className="mt-3">
                                            {error}
                                        </Alert>
                                    )}
                                    <button type="submit" className="btn btn-primary w-100">Send Reset Link</button>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <EVImage />
            </Col>
        </Row>
    );
};

export default ForgotPassword;
