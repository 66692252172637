import React from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import '../shared/form-elements.css';
import './home.css'

const HomeForm = () => {
    return (
        <>
            <Row>
                <Col md={12}>
                    <h1 className='home_screen_content_text' >
                        Maximise Your EV's Value With a Professional Battery Health Test
                    </h1>
                </Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
                <Col md={6}>
                    <LinkContainer to="/login" className="p-1">
                        <Nav.Link>
                            <Button variant="primary" className="w-100 home-login-btn">
                                Log In
                            </Button>
                        </Nav.Link>
                    </LinkContainer>
                </Col>
                <Col md={6}>
                    <LinkContainer to="/register" className="p-1">
                        <Nav.Link>
                            <Button variant="primary" className="w-100">
                                Register
                            </Button>
                        </Nav.Link>
                    </LinkContainer>
                </Col>
            </Row>
        </>
    );
};

export default HomeForm;
