import StandaloneMessagePage from "../shared/StandaloneMessagePage";
import passwordChangedIcon from '../assets/password_changed.png'

const PasswordChanged = () => {
    return (
        <StandaloneMessagePage
            h1Text={"Password successfully changed"}
            pText={"You can now log in using your new password."}
            icon={passwordChangedIcon}
        />
    );
}

export default PasswordChanged