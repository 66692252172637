import ConfirmationPopup from '../shared/ConfirmationPopup';
import cancelIcon from '../assets/cancel.png'

const CancelEvaluationModal = ({ handleAffirmative, showModal, setShowModal }) => {
    return (
        <ConfirmationPopup
            showModal={showModal}
            setShowModal={setShowModal}
            title={"Confirm Cancellation"}
            bodyDesc={"Are you sure you want to cancel battery assessment?"}
            icon={cancelIcon}
            handleAffirmative={handleAffirmative}
        />
    );
}

export default CancelEvaluationModal