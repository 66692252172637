import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../shared/form-containers.css';
import EvaluationsApi from '../apis/Evaluations';
import CancelEvaluationModal from './CancelEvaluationModal';
import FormTooltip from '../shared/FormTooltip';
import { MAXIMUM_INITIAL_SOC } from './config';
import confirmIcon from '../assets/confirm.png'
import ConfirmationPopup from '../shared/ConfirmationPopup';

import "./PreliminaryDetailsEntryPage.css"

const evaluationsApi = new EvaluationsApi(null, null, false);

const batteryCapacityTooltip = `Enter the net battery capacity of your EV in kilowatt-hours (kWh). This value is available in your vehicle's user manual, dashboard, or specifications sheet. Example: If your EV’s battery capacity is 50 kWh, enter "50."`;
const startOfSessionTooltip = `Enter the battery percentage level at the beginning of the charging session. This is typically displayed on the vehicle dashboard or charging station. Example: If the battery was at 20% when you started charging, enter "20."`;
const emailTooltip = `Enter a valid email address to receive the battery health assessment results. Ensure there are no typos for seamless delivery. Example: Enter an email like "example@domain.com."`;

const validationSchema = Yup.object().shape({
    batteryPercentage: Yup.number()
        .required('Battery percentage is required')
        .max(MAXIMUM_INITIAL_SOC, `Battery percentage must not be greater than ${MAXIMUM_INITIAL_SOC}%`),
    batteryCapacity: Yup.number()
        .required('Battery capacity is required')
        .positive('Battery capacity must be a positive number'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
});

const PreliminaryDetailsEntryPage = ({ evaluation, setEvaluation }) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showContinueModal, setShowContinueModal] = useState(false);
    const [formValues, setFormValues] = useState(null);

    const handleContinue = () => {
        if (formValues) {
            evaluationsApi.start(evaluation.code, formValues.batteryPercentage, formValues.batteryCapacity, formValues.email).then((r) => {
                setEvaluation(r.data);
                setShowContinueModal(false);
            });
        }
    };

    const handleCancel = () => {
        evaluationsApi.cancel(evaluation.code).then((r) => setEvaluation(r.data));
    };

    return (
        <Row className="rbh-container preliminary-container">
            <div>
                <p className='preliminary-title'>Battery Assessment</p>
                <p className='preliminary-subtitle'>Charge your car to start your battery health check</p>
                <p className='preliminary-subtitle'>Please enter the following details:</p>
            </div>
            <Formik
                initialValues={{
                    batteryPercentage: '',
                    batteryCapacity: '',
                    email: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    setFormValues(values);
                    setShowContinueModal(true);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form className="preliminary-form" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="batteryCapacity" className="mt-3">
                            <FormTooltip className="preliminary-label" label="Battery Capacity" tooltip_text={batteryCapacityTooltip} />
                            <Field
                                type="number"
                                name="batteryCapacity"
                                placeholder='Enter the net battery capacity of the EV in "kWh"'
                                value={values.batteryCapacity}
                                onChange={handleChange}
                                className={`form-control ${touched.batteryCapacity && errors.batteryCapacity ? 'is-invalid' : ''} preliminary-input`}
                            />
                            <ErrorMessage component="div" name="batteryCapacity" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group controlId="batteryPercentage" className="mt-3">
                            <FormTooltip label="Battery percentage at start of charging session" tooltip_text={startOfSessionTooltip} />
                            <Field
                                type="number"
                                name="batteryPercentage"
                                placeholder="Enter the current battery percentage"
                                value={values.batteryPercentage}
                                onChange={handleChange}
                                className={`form-control ${touched.batteryPercentage && errors.batteryPercentage ? 'is-invalid' : ''} preliminary-input`}
                            />
                            <ErrorMessage component="div" name="batteryPercentage" className="invalid-feedback" />
                        </Form.Group>

                        <Form.Group controlId="email" className="mt-3">
                            <FormTooltip className="preliminary-label" label="Email" tooltip_text={emailTooltip} />
                            <Field
                                type="email"
                                name="email"
                                placeholder="Enter your email address"
                                value={values.email}
                                onChange={handleChange}
                                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''} preliminary-input`}
                            />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </Form.Group>

                        <Row className="flex mt-3">
                            <Col className="text-end col-md-6">
                                <Button onClick={() => setShowCancelModal(true)} className="btn-cancel">
                                    Cancel
                                </Button>
                            </Col>
                            <Col className="text-end col-md-6">
                                <Button type="submit" className="btn-start" disabled={Object.values(values).some(value => !value) || Object.keys(errors).length > 0}>
                                    Start
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>

            <ConfirmationPopup
                showModal={showContinueModal}
                setShowModal={setShowContinueModal}
                title={"Confirm Start"}
                bodyDesc={"Is the vehicle plugged in and charging?"}
                icon={confirmIcon}
                handleAffirmative={handleContinue}
            />
            <CancelEvaluationModal
                showModal={showCancelModal}
                setShowModal={setShowCancelModal}
                handleAffirmative={handleCancel}
            />
        </Row>
    );
};

export default PreliminaryDetailsEntryPage;
