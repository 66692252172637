import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { joinUrl } from '../util';
import '../shared/form-containers.css'
import '../shared/StandaloneMessagePage.css';
import './GetUrl.css'
import getUrlIcon from '../assets/get_url.png'

const GetUrl = () => {
    const { evaluationCode } = useParams();
    const url = joinUrl(window.location.origin, "/evaluations", evaluationCode);
    const [showLinkCopied, setShowLinkCopied] = useState(false);
    const handleCopyUrl = (url) => {
        navigator.clipboard.writeText(url);
        setShowLinkCopied(true);
        setTimeout(() => setShowLinkCopied(false), 2000);
    };
    return (
        <div className="rbh-container row">
            <div className="standalone-success-container">
                <div className="standalone-content">
                    <h1>Health Test</h1>
                    <div className="standalone-icon-wrapper" >
                        <img className='get-url-icon' src={getUrlIcon} alt="" />
                    </div>
                    <p>
                        Click on the link below to start test or copy to clipboard to send to Tester via email or SMS.
                    </p>
                    <h5 className="click-open-test">
                        <a href={url}>Click to open test</a>
                    </h5>
                    {showLinkCopied && <Alert size="sm" style={{ padding: '5px 10px', fontSize: '14px', lineHeight: '1.2em' }} variant="warning">{"Link copied to clipboard!"}</Alert>}

                    <div className="button-container">
                        <Link to="/dashboard" className="button-back">
                            Back to Dashboard
                        </Link>
                        <button className="button-copy" onClick={() => handleCopyUrl(url)}>
                            Copy link to clipboard
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default GetUrl;
