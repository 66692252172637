import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './ConfirmationPopup.css'

const ConfirmationPopup = ({
    showModal,
    setShowModal,
    title,
    bodyDesc,
    icon,
    handleAffirmative,
    handleNegative = null,
    affirmativeActionText = "Yes",
    negativeActionText = "No"
}) => {
    return (
        <Modal
            className="modal-custom"
            show={showModal}
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title className="modal-title">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='modal-body-desc'>{bodyDesc}</p>
            </Modal.Body>
            <img
                src={icon}
                alt="icon"
                className="modal-icon"
            />
            <Modal.Footer>
                <Button onClick={handleNegative || (() => setShowModal(false))} className="btn-no">
                    {negativeActionText}
                </Button>
                <Button onClick={handleAffirmative} className="btn-yes">
                    {affirmativeActionText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationPopup