import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoginForm from './LoginForm';
import '../shared/form-containers.css'
import './Login.css'
import AuthNav from '../shared/AuthNav';
import EVImage from '../shared/EVImage';

const Login = () => {
    return (
        <Row className="rbh-container login-screen-wrapper">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container login-container">
                        <h1 className="login-title">Login</h1>
                        <br></br>
                        <LoginForm />
                        <AuthNav label={"Register"} explanatoryText={"Don't have an account?"} linkTo={"/register"} />
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <EVImage />
            </Col>
        </Row>
    );
};

export default Login;
