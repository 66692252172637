import { joinUrl } from '../util';
import { Button, Badge } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './Dashboard.css'
import { EvaluationStatus } from '../consts';

export function formatIrishDateTime(started_at) {
    if (!started_at) {
        return '';
    }
    const date = new Date(started_at);
    return date.toLocaleString('en-IE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
}

const evaluation_statuses_display = {
    [EvaluationStatus.WELCOME]: "WELCOME SCREEN",
    [EvaluationStatus.NOT_STARTED]: "NOT STARTED",
    [EvaluationStatus.RUNNING]: "RUNNING",
    [EvaluationStatus.SUCCEEDED]: "SUCCEEDED",
    [EvaluationStatus.FAILED]: "FAILED",
    [EvaluationStatus.FAILED_VEHICLE_ALREADY_RUNNING]: "FAILED - VEHICLE ALREADY RUNNING",
    [EvaluationStatus.CANCELLED]: "CANCELLED"
};

const smallButtonStyle = {
    fontSize: '12px',
    padding: '5px 10px',
    width: '100px',
    textAlign: 'center',
};

// Column configurations for regular users
const regularUserColumns = [
    {
        name: "Created date",
        selector: (row) => formatIrishDateTime(row.created_at),
        style: { fontSize: '12px' },
        sortable: true,
        width: '12%',
    },
    {
        name: (
            <div>
                Vehicle<br />make
            </div>
        ),
        selector: (row) => row.vehicle?.make?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '12%',
    },
    {
        name: (
            <div>
                Vehicle<br />model
            </div>
        ),
        selector: (row) => row.vehicle?.model?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '13%',
    },
    {
        name: (
            <div>
                Vehicle<br />year
            </div>
        ),
        selector: (row) => row.vehicle?.year?.value || '',
        style: { fontSize: '12px' },
        sortable: true,
        width: '8%',
    },
    {
        name: "Status",
        selector: (row) => {
            const statusDisplay = evaluation_statuses_display[row.status];
            if (statusDisplay.startsWith("FAILED -")) {
                const [status, ...tooltipParts] = statusDisplay.split(" - ");
                const tooltip = tooltipParts.join(" - ");
                return (
                    <div title={tooltip}>
                        <Badge bg="primary">{status}</Badge>
                    </div>
                );
            } else {
                return (
                    <div>
                        <Badge bg="primary">{statusDisplay}</Badge>
                    </div>
                );
            }
        },
        style: { fontSize: '12px' },
        wrap: true,
        sortable: true,
        sortFunction: (a, b) => { if (a.status > b.status) return -1; if (a.status < b.status) return 1; return 0; },
        width: '10%',
    },
    {
        name: "Start time",
        selector: (row) => formatIrishDateTime(row.started_at),
        sortable: true,
        width: '15%',
    },
    {
        name: "Finish time",
        selector: (row) => formatIrishDateTime(row.finished_at),
        sortable: true,
        width: '15%',
    },
    {
        name: "Reports",
        selector: (row) => (
            (
                row.status === EvaluationStatus.NOT_STARTED ||
                row.status === EvaluationStatus.WELCOME ||
                row.status === EvaluationStatus.RUNNING ||
                row.status === EvaluationStatus.CANCELLED
            ) ?
                <div>
                    <LinkContainer to={joinUrl('/get-url', row.code)} className="p-1">
                        <Nav.Link>
                            <Button variant="primary" style={smallButtonStyle}>
                                Test URL
                            </Button>
                        </Nav.Link>
                    </LinkContainer>
                </div> :
                (
                    row.status === EvaluationStatus.SUCCEEDED ?
                        <LinkContainer to={joinUrl('/report', row.code)} className="p-1">
                            <Nav.Link>
                                <Button variant="primary" style={smallButtonStyle}>
                                    View Report
                                </Button>
                            </Nav.Link>
                        </LinkContainer> : null
                )
        ),
        width: '15%',
    },
];


const adminUserColumns = [
    ...regularUserColumns.map((col) => ({
        ...col,
        width: adjustWidthForAdmin(col.width),
    })),
    {
        name: (
            <div>
                Company<br />name
            </div>
        ),
        selector: (row) => row.user.name,
        style: { fontSize: '12px' },
        sortable: true,
        width: '10%',
    },
];

function adjustWidthForAdmin(width) {
    const widthPercentage = parseFloat(width.replace('%', ''));
    const adjustedPercentage = (widthPercentage * 0.9).toFixed(1);
    console.log(`Original: ${width}, Adjusted: ${adjustedPercentage}%`);
    return `${adjustedPercentage}%`;
}

export function getEvaluationsTableColumns(user) {
    console.log(adminUserColumns);
    return user.role === 'admin' ? adminUserColumns : regularUserColumns;
}
