import { useState, useEffect } from 'react';
import ApiService from '../apis/ApiService';

const apiService = new ApiService(null, null, false);

export const useEvaluation = (evaluationCode, error, errorDescription) => {
    const [evaluation, setEvaluation] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (evaluationCode && !error && !errorDescription) {
            function getEvaluation() {
                return apiService._get(`evaluations/${evaluationCode}/`)
                    .then(response => {
                        const response_evalution = response?.data
                        setEvaluation(response_evalution);
                        return false;
                    })
                    .catch(error => {
                        console.error('Error fetching evaluation details:', error);
                        // Keep the spinner running if the error is aborted (ECONNABORTED)
                        if (error.code === 'ECONNABORTED') {
                            return true;
                        }

                        // Stop the spinner otherwise
                        return false;
                    });
            }

            setIsLoading(true)
            getEvaluation().then(s => setIsLoading(s)).catch(() => setIsLoading(false))
        }
    }, [evaluationCode, error, errorDescription]);

    return { evaluation, setEvaluation, isLoading };
};
