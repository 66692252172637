import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../shared/form-containers.css';
import RegistrationForm from './RegistrationForm';
import './register.css';
import AuthNav from '../shared/AuthNav';
import EVImage from '../shared/EVImage';

const Register = () => {
    return (
        <Row className="rbh-container register-screen-wrapper">
            <Col style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container register-container">
                        <h1 className='register-title'>Register here</h1>
                        <p className='all-fields-are-mandatory'>All fields are mandatory</p>
                        <br />
                        <RegistrationForm />
                        <AuthNav label={"Login"} explanatoryText={"Already have an account?"} linkTo={"/login"} />
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <EVImage />
            </Col>
        </Row>
    );
};

export default Register;
