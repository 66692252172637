import ApiService from './ApiService';

class UsersApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    is_active(verification_guid) {
        return this._get(`/users/${verification_guid}/is_active`)
    }

    activate(verification_guid) {
        return this._post(`/users/${verification_guid}/activate`)
    }

    send_reset_password_email(email) {
        return this._post(`/users/send_reset_password_email`, { email })
    }

    reset_password_has_expired(verification_guid) {
        return this._post(`/users/${verification_guid}/reset_password_has_expired`);
    }

    reset_password(verification_guid, password) {
        return this._post(`/users/${verification_guid}/reset_password`, {
            password,
        });
    }
}

export default UsersApi;
