import ApiService from './ApiService';

class ReportsApi extends ApiService {
    constructor(navigate, fallbackRoute, authProtected = true) {
        super(navigate, fallbackRoute, authProtected);
    }

    errors_report() {
        return this._get(`/errors_report`, { responseType: 'blob' });
    }

}

export default ReportsApi;
