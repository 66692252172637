import StandaloneMessagePage from '../shared/StandaloneMessagePage';
import emailSentIcon from '../assets/email_sent.png'

const ForgotPasswordMailSent = () => {
    return (
        <StandaloneMessagePage
            h1Text={"Reset Password"}
            pText={"A password reset link has been sent to your email address. Please check your inbox and follow the instructions."}
            icon={emailSentIcon}
        />
    );
}

export default ForgotPasswordMailSent;
