import StandaloneMessagePage from "../shared/StandaloneMessagePage";
import registeredIcon from '../assets/registered.png'

const RegisterSuccess = () => {
    return (
        <StandaloneMessagePage
            h1Text={"Thank you for registering"}
            pText={"Check your inbox for a verification email to confirm your account"}
            icon={registeredIcon}
        />
    );
}

export default RegisterSuccess;
