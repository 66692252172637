import React from 'react';
import { Row, Col } from 'react-bootstrap';
import HomeForm from './HomeForm';
import '../shared/form-containers.css'
import EVImage from '../shared/EVImage';

const Home = () => {
    return (
        <Row className="rbh-container home-container">
            <Col>
                <Row className="text-start align-items-start">
                    <Col md={12} lg={6} className="rbh-form-container home-screen-text-wrapper">
                        <HomeForm />
                    </Col>
                </Row>
            </Col>
            <Col className='d-none d-lg-block'>
                <EVImage />
            </Col>
        </Row>
    );
};

export default Home;
